define("discourse/plugins/tacks-plugin/discourse/templates/connectors/before-groups-index-container/show-user-points-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.tacks_show_ui_elements}}
    {{#if this.currentUser}}
      <li class="user-points">
        You currently have
        <a class="user-link">
          <span class="user-menu-dropdown-text">{{this.points}} </span>
        </a>
        Tacks.
        <a
          href="{{this.siteSettings.tacks_form_link}}"
          target="_blank"
          class="btn btn-primary"
        >Purchase Tacks</a>
      </li>
      <br />
    {{else}}
      <li class="user-points">
        <a
          href="{{this.siteSettings.tacks_form_link}}"
          target="_blank"
          class="btn btn-primary"
        >Purchase Tacks</a>
      </li>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "2wxCxJrc",
    "block": "[[[41,[30,0,[\"siteSettings\",\"tacks_show_ui_elements\"]],[[[41,[30,0,[\"currentUser\"]],[[[1,\"    \"],[10,\"li\"],[14,0,\"user-points\"],[12],[1,\"\\n      You currently have\\n      \"],[10,3],[14,0,\"user-link\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"user-menu-dropdown-text\"],[12],[1,[30,0,[\"points\"]]],[1,\" \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      Tacks.\\n      \"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"tacks_form_link\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"btn btn-primary\"],[12],[1,\"Purchase Tacks\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"li\"],[14,0,\"user-points\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"tacks_form_link\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"btn btn-primary\"],[12],[1,\"Purchase Tacks\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"li\",\"a\",\"span\",\"br\"]]",
    "moduleName": "discourse/plugins/tacks-plugin/discourse/templates/connectors/before-groups-index-container/show-user-points-group.hbs",
    "isStrictMode": false
  });
});