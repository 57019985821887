define("discourse/plugins/tacks-plugin/discourse/templates/connectors/above-user-summary-stats/show-user-points", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-points">
    <p><strong>{{i18n "user.points.title"}}:</strong> {{points}}</p>
  </div>
  */
  {
    "id": "ynTooCaj",
    "block": "[[[10,0],[14,0,\"user-points\"],[12],[1,\"\\n  \"],[10,2],[12],[10,\"strong\"],[12],[1,[28,[35,3],[\"user.points.title\"],null]],[1,\":\"],[13],[1,\" \"],[41,[28,[32,0],[\"points\"],null],[[[8,[39,5],null,null,null]],[]],[[[44,[[28,[37,7],null,[[\"points\"],[[28,[32,1],[\"points\"],null]]]]],[[[1,[52,[30,1,[\"points\"]],[28,[30,1,[\"points\"]],null,null],[28,[32,2],[[30,0],\"points\",\"[\\\"The `points` property path was used in the `discourse/plugins/tacks-plugin/discourse/templates/connectors/above-user-summary-stats/show-user-points.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.points}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n\"],[13]],[\"maybeHelpers\"],false,[\"div\",\"p\",\"strong\",\"i18n\",\"if\",\"points\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/tacks-plugin/discourse/templates/connectors/above-user-summary-stats/show-user-points.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});